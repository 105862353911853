@import "../slick/slick.css";
@import "../slick/slick-theme.css" .slide img {
  width: 20rem;
}

.slide {
  transform: scale(0.8);
  transition: transform 500ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  background-color: rgb(89, 28, 231);
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #7e18ad;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.slick-next {
  position: absolute;
  right: 0%;
  top: 0%;
  content: "";
  color: black;
}

.slick-prev {
  color: black;
}

.slick-next:after {
  content: ">";
}

.slick-prev:before {
  content: "<";
}
